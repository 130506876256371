
























































































































































































































































































































































import moment from 'moment'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import InvoicePlans from './components/InvoicePlans.vue'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Currency } from '@/edshed-common/api/types/currency'
import { Api, CountryCode, CountryInfo, InvoiceFilter, PagedResults, SalesSourceDepts, SalesPerson, StripeInvoiceInfo, SubscriptionPlanInfo, TableState } from '@/edshed-common/api'

@Component({
  name: 'InvoicesView',
  components: { InvoicePlans }
})

export default class Invoices extends Mixins(ComponentHelper) {
  loading: boolean = false
  currency: Currency[] = [...Currency]

  invoicesData: PagedResults<StripeInvoiceInfo> = { // cannot set PagedResults<StripeInvoiceInfo> (readonly)
    items: [],
    total: 0
  }

  table: TableState = {
    page: 1,
    perPage: 25,
    sort: 'date',
    dir: 'desc',
    term: ''
  }

  filters: InvoiceFilter = {
    edshed: true,
    litshed: true,
    uk: true,
    us: true,
    id: undefined
  }

  currencies: Currency[] | null = null
  selectedCountries: CountryCode[] | null = null
  invoiceType: 'subscription_cycle' | 'subscription_create' | null = null

  persons: SalesPerson[] = []

  depts = SalesSourceDepts

  response: string | null = null

  plans: SubscriptionPlanInfo[] = []

  countriesData: CountryInfo[] = []
  filteredDates: Date[] | null = null
  async mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      this.loading = true
      await this.getSalesPersons()
      await this.getInvoices()
      await this.getPlans()
      await this.getCountries()
      this.loading = false
    }
  }

  clearFilters () {
    this.filters = {
      edshed: true,
      litshed: true,
      uk: true,
      us: true,
      id: undefined
    }
    this.filteredDates = null
    this.selectedCountries = null
    this.currencies = null
    this.onFilterChange()
  }

  async getPlans () {
    try {
      this.plans = await Api.getSubscriptionPlans({}, undefined)
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load plans',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async getInvoices () {
    this.loading = true
    try {
      if (this.selectedCountries) {
        this.filters.countries = this.selectedCountries
      }
      if (this.currencies) {
        this.filters.currencies = this.currencies
      }
      if (this.filteredDates && this.filteredDates[0]) {
        this.filters.startDate = this.filteredDates[0]
      }
      if (this.filteredDates && this.filteredDates[1]) {
        this.filters.endDate = this.filteredDates[1]
      }
      if (this.invoiceType) {
        this.filters.invoiceType = this.invoiceType
      }
      this.invoicesData = await Api.getInvoices({ skip: (this.table.page - 1) * this.table.perPage, take: this.table.perPage, sort: this.table.sort, dir: this.table.dir, term: this.table.term }, this.filters)
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load invoices',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }

  async getSalesPersons () {
    try {
      const sales_persons = await Api.getSalesPersons({}, { active: 'Active' })
      this.persons = sales_persons.items
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not get Sales Persons',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async getCountries () {
    try {
      this.countriesData = await Api.getCountries()
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not get Countries',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }

  async saveSourcePerson (personId: number, invoiceId: number) {
    try {
      await Api.setInvoiceSourcePerson(invoiceId, personId)

      this.$buefy.toast.open({
        message: 'Source person saved successfully!',
        type: 'is-success',
        position: 'is-bottom',
        duration: 5000
      })
    } catch (err) {
      console.log(err)
      this.$buefy.toast.open({
        message: 'Could not save the source person',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 5000
      })
    }
  }

  async saveSourceDept (obj: StripeInvoiceInfo) {
    try {
      const id = obj.id
      if (!obj.source_dept) {
        throw new Error('No dept to save!')
      }
      const dept = obj.source_dept

      await Api.setInvoiceSourceDept(id, dept)
      this.$buefy.toast.open({
        message: 'Source dept saved successfully!',
        type: 'is-success',
        position: 'is-bottom',
        duration: 5000
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not save the source dept',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 5000
      })
    }
  }

  onPageChange (page: number) {
    this.table.page = page
    this.getInvoices()
  }

  async onFilterChange () {
    this.table.page = 1
    await this.getInvoices()
  }

  async setLocales (val: (CountryCode | null)[] | null) {
    if (val && val.includes(null)) {
      this.selectedCountries = null
      if (this.filters.countries) {
        delete this.filters.countries
      }
    } else {
      this.selectedCountries = val as CountryCode[]
    }

    await this.onFilterChange()
  }

  async setCurrencies (val: (Currency | null)[] | null) {
    if (val && val.includes(null)) {
      this.currencies = null
      if (this.filters.currencies) {
        delete this.filters.currencies
      }
    } else if (val) {
      this.currencies = val as Currency[]
    }

    await this.onFilterChange()
  }

  formattedDate (d) {
    return moment(d).format('DD/MM/YYYY')
  }
}
